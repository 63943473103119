import React from "react"
import sectionStyles from "./section.module.scss"


const section = ({isBlack, title, image="", imageAlt="", text=[] }) => {
  return (
    <section className={isBlack}>
      <wrapper className={sectionStyles.section}> 
        <h2>{title}</h2>
        <div className={sectionStyles.section__container}>
          {/* <div className={sectionStyles.image}>
            <img src={image} alt={imageAlt} />
          </div> */}
          <div className={sectionStyles.section__text}>
            {text.map(item=><li>{item}</li>)}
          </div>
        </div>
      </wrapper>
    </section>
  )
}

export default section
