import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import Section from "../components/section"

const WebDevelopmentPage = () => {
  return (
    <Layout
      title="Web & App Development"
      btnText="Get a quote"
      btnLink="/contact"
    >
      <Head title="Web & App Development" />
      <main>
        <Section
          isBlack="black"
          title="What we do"
     
          text={[
            `We at Killer Click do our best to provide the best user experience
              they can get from a web or a mobile app. Our websites convert
              better and with good content have a great potential to rank high
              on search engine results pages.`,
          ]}
        />
        <Section
          isBlack=""
          title="How long does it take for your website to load?"
          
          text={[
            `People expect speed. Google expects speed. Give them what they are
              looking for and give it to them fast.`,
          ]}
        />
        <Section
          isBlack="black"
          title="Technologies"

          text={[
            `We use the latest technologies
              (React/NextJs/Gatsby/Express/Laravel/WordPress) to provide the
              solution you need and which your budget can handle.`,
          ]}
        />
        <Section
          isBlack=""
          title="Let's get started"


          text={[
            `Do you need an eCommerce site or a corporate site?`,
            `Payment gateway
              implementation?`,
            ` Custom landing page?`,
            `Contact us and let's get
              started.`,
          ]}
        />
      
         
      </main>
    </Layout>
  )
}

export default WebDevelopmentPage
